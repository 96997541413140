<script setup lang="ts">
/**
 * @author Beka Chkhaidze
 */
import { ref, watch } from "vue";
import { useBookingStore } from "@/store/booking";
import { useCalendar, formatter } from "@/components/regularComponents/FormComponents/CustomCalendar/ts/calendar";

import type { BaseTaxonomy } from "@/store/main/types/widgets-terms/terms";

import TimesList from "@/components/smartComponents/ContactUs/parts/TimesList.vue";
import Button from "../Button.vue";

defineEmits(["confirm"]);
const bookingStore = useBookingStore();

const { getBookedHours } = useCalendar();
const bookedHours = ref<string[]>();

const onSelect = (item: BaseTaxonomy) => {
  bookingStore.time = item?.title;
};

watch(
  () => bookingStore.date,
  (n) => {
    bookedHours.value = getBookedHours(formatter(new Date(n)));
  },
  { immediate: true }
);
</script>

<template>
  <section>
    <h2 class="video-call__title">
      {{ bookingStore.getFormatedDate }}
    </h2>
    <p class="video-call__paragraph">
      {{ $t("you are on a video call with a dome consultant") }}
    </p>
    <TimesList
      @select="onSelect"
      v-bind="{ calendarModal: bookingStore.date, bookedHours, hideTitle: true }"
    />

    <Button
      :isDisabled="!bookingStore.time"
      btnText="confirm"
      full
      class="mt-24"
      @click="$emit('confirm')"
    />
  </section>
</template>
