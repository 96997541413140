<script setup lang="ts">
/**
 * @author Luka Tchigladze
 */

/** IMPORTS */
import { ref, computed, onMounted, defineAsyncComponent } from "vue";
import type { Ref, ComputedRef } from "vue";

import { reqAxios } from "@/composables/core/func";

import { useMainStore } from "@/store/main/index";
import { useBookingStore } from "@/store/booking";

import { buildLinkObjFromMenu } from "@/composables/utils/router";
import { sendEvent } from "@/composables/utils/sendEvent";

import { getWidgetSS } from "@/composables/core/func";

import LanguageDropdown from "@/components/regularComponents/UIComponents/Dropdowns/LanguageDropdown.vue";
import Button from "@/components/regularComponents/UIComponents/Button.vue";

import MenuLogo from "@/components/regularComponents/UIComponents/SVG/MenuLogo.vue";
import X from "@/components/regularComponents/UIComponents/SVG/actions/X.vue";
import DownArrow from "@/components/regularComponents/UIComponents/SVG/chevrons/DownArrow.vue";
import TimeSelectModal from "./Modals/TimeSelectModal.vue";

import type { IndxInterface } from "@/store/main/types/StateType";

const VideoCallModal = defineAsyncComponent(
  () => import("@/components/regularComponents/UIComponents/Modals/VideoCallModal.vue")
);
const BaseModal = defineAsyncComponent(
  () => import("@/components/regularComponents/UIComponents/Modals/BaseModal.vue")
);
const CalendarModal = defineAsyncComponent(
  () =>
    import(
      "@/components/regularComponents/UIComponents/Modals/CalendarModal/CalendarModal.vue"
    )
);
const ResponseModal = defineAsyncComponent(
  () => import("@/components/regularComponents/UIComponents/Modals/ResponseModal.vue")
);

const Link = defineAsyncComponent(
  () => import("@/components/regularComponents/UIComponents/Link.vue")
);

// EMITS
const emit = defineEmits(["close"]);

// STORE
const mainStore = useMainStore();
const bookingStore = useBookingStore();

const route = useRoute();

const windowWidth: ComputedRef<number> = computed(() => mainStore.windowWidth);

const lang = computed(() => mainStore.lang);

const isDesktop = computed(() => windowWidth.value > mainStore.breakpoints.tablet);

const isMobile = computed(() => windowWidth.value <= mainStore.breakpoints.mobile);

const animate = ref(false);
const animationEnd = ref(false);
const activeIndex: Ref<number> = ref(-1);
const isModalOpen = ref(false);
const isSecondModalOpen = ref(false);
const isThirdModalOpen = ref(false);
const isResponseOpen: Ref<boolean> = ref(false);
const isSuccessful: Ref<boolean> = ref(false);
const isRequestFinished = ref(false);

await getWidgetSS(["socials"]);

const indx = useState<IndxInterface>("data");

const menus = computed(() => {
  if (indx.value?.menus) {
    const menuItems = indx.value?.menus?.filter(
      (x) => x.menu_type === "main_menu" && !x.hidden && !x.pid
    );

    return menuItems;
  }

  return [];
});

const socials = computed(() => {
  const values = { ...mainStore.widgets?.["socials"] };
  if (!values) return;

  delete values?.title;

  return Object.values(values);
});

const submenus = computed(() => {
  if (indx.value?.menus) {
    const menuItems = indx.value?.menus?.filter(
      (x) => x.menu_type === "sub_menu" && !x.hidden
    );

    return menuItems;
  }
});

const openModal = () => {
  isModalOpen.value = true;

  // if (!route.params?.slugs?.length) {
  //   sendEvent("Instant video call - form open");
  // }

  sendEvent("form-call", { formType: "Instant video call" });
};

const handleStep1 = () => {
  isModalOpen.value = false;

  if (isMobile.value) {
    isSecondModalOpen.value = true;
  } else {
    isSecondModalOpen.value = false;
    isThirdModalOpen.value = true;
  }
};

const handleStep2 = () => {
  isModalOpen.value = false;
  isSecondModalOpen.value = false;
  isThirdModalOpen.value = true;
};

const handleStep3 = async () => {
  isModalOpen.value = false;
  isSecondModalOpen.value = false;
  isThirdModalOpen.value = false;
  isResponseOpen.value = true;

  const params = {
    date: bookingStore.getFormatForForm,
    time: bookingStore.time,
    dial: bookingStore.dial,
    timezone: bookingStore.timezone?.title,
    email: bookingStore.email,
    name: bookingStore.name,
  };

  isRequestFinished.value = false;
  const response = await reqAxios("main/saveSubmitedForm", {
    formType: "bookedCalls",
    ...params,
  });
  isRequestFinished.value = true;
  isSuccessful.value = response?.data?.[0] === "success";

  // if (!route.params?.slugs?.length) {
  //   sendEvent("Instant video call - submission");
  // }
  
  if (isSuccessful.value) {
    sendEvent("form-submission", { formType: "Instant video call" });
  }

  bookingStore.$reset();
};

const handleBack1 = () => {
  isModalOpen.value = true;
  isSecondModalOpen.value = false;
  isThirdModalOpen.value = false;
};

const handleBack2 = () => {
  if (isMobile.value) {
    // isModalOpen.value = false;
    isSecondModalOpen.value = true;
  } else {
    isModalOpen.value = true;
  }
  isThirdModalOpen.value = false;

  bookingStore.name = null;
  bookingStore.email = null;
};

const delay = 300;
const step = 200;
const duration = 600;
const durationSmall = 400;
const maxDelay = computed(() => {
  return delay + (menus.value.length - 1) * step;
});
const dots = 4;

// METHODS
const setActive = (id: number) => {
  if (isDesktop.value) {
    activeIndex.value = id;
  }
};

const closeModal = () => {
  emit("close");
};

// MOUNTED
onMounted(() => {
  setTimeout(() => {
    animate.value = true;
  }, 200);

  setTimeout(() => {
    animationEnd.value = true;
  }, 200 + maxDelay.value + duration);
});
</script>

<template>
  <menu
    class="menu d-flex pos-fixed bg-white w-full h-full"
    :class="{ animate: animate, 'animate-end': animationEnd }"
    :style="{
      '--delay': delay + 'ms',
      '--duration': maxDelay - delay + duration + 'ms',
      '--duration-small': durationSmall + 'ms',
    }"
  >
    <div class="menu__left w-1/2 pos-rel">
      <div class="menu__top d-flex j-btw al-center">
        <div class="menu__logo-lang d-flex al-center">
          <router-link :to="`/${mainStore.lang}`" class="menu__logo d-flex al-center">
            <MenuLogo />
          </router-link>
          <ClientOnly>
            <LanguageDropdown />
          </ClientOnly>
        </div>

        <Button
          icon="video"
          :btnType="5"
          btnText="instant video call"
          @click="openModal"
        />
      </div>
      <Teleport to="body">
        <Transition name="fade-out">
          <BaseModal
            v-if="isModalOpen"
            @close="isModalOpen = false"
            maxWidth="817px"
            title="Select Date"
          >
            <CalendarModal @clicked="handleStep1" class="burger-menu__calendar" />
          </BaseModal>
        </Transition>
      </Teleport>

      <Teleport to="body">
        <Transition name="fade-out">
          <BaseModal
            v-if="isSecondModalOpen"
            title="Schedule Meeting"
            :hasBackButton="true"
            @close="isSecondModalOpen = false"
            @back="handleBack1"
          >
            <TimeSelectModal @confirm="handleStep2" />
          </BaseModal>
        </Transition>
      </Teleport>

      <Teleport to="body">
        <Transition name="fade-out">
          <BaseModal
            v-if="isThirdModalOpen"
            title="Schedule Meeting"
            :hasBackButton="true"
            @close="isThirdModalOpen = false"
            @back="handleBack2"
          >
            <VideoCallModal @clicked="handleStep3" />
          </BaseModal>
        </Transition>
      </Teleport>

      <Teleport to="body">
        <Transition name="fade-out">
          <BaseModal v-if="isResponseOpen" @close="isResponseOpen = false">
            <ResponseModal :success="isSuccessful" @close="isResponseOpen = false" />
          </BaseModal>
        </Transition>
      </Teleport>
      <div
        class="menu__items d-flex dir-col"
        :style="{
          '--delay': delay + 'ms',
          '--duration': duration + 'ms',
        }"
        v-if="isDesktop"
      >
        <template v-for="(item, index) in menus">
          <Link
            v-if="item?.titles?.[lang]?.title"
            class="menu__item pos-rel c-black uppercase d-flex al-center"
            :label="item?.titles?.[lang]?.title"
            :class="{
              'menu__item--disabled': activeIndex !== -1 && activeIndex !== index,
            }"
            :style="{
              'transition-delay': delay + step * index + 'ms',
            }"
            @mouseenter="setActive(index)"
            @mouseleave="setActive(-1)"
            @click="setActive(index)"
            :link="
              item?.url_slug === 'projects'
                ? buildLinkObjFromMenu(item, '?page=1&type=current')
                : buildLinkObjFromMenu(item)
            "
          >
            {{ item?.titles?.[lang]?.title }}

            <DownArrow />
          </Link>
        </template>
      </div>
      <div
        class="menu__items d-flex dir-col"
        :style="{
          '--delay': delay + 'ms',
          '--duration': duration + 'ms',
        }"
        v-else
      >
        <template v-for="(item, index) in menus">
          <Link
            v-if="item?.titles?.[lang]?.title"
            :label="item?.titles?.[lang]?.title"
            class="menu__item pos-rel c-black uppercase"
            :class="{
              'menu__item--disabled': activeIndex !== -1 && activeIndex !== index,
            }"
            :style="{
              'transition-delay': delay + step * index + 'ms',
            }"
            :link="
              item?.url_slug === 'projects'
                ? buildLinkObjFromMenu(item, '?page=1&type=current')
                : buildLinkObjFromMenu(item)
            "
          >
            {{ item?.titles?.[lang]?.title }}
          </Link>
        </template>
      </div>
    </div>

    <div class="menu__right w-1/2 pos-rel">
      <div class="menu__social-submenu">
        <div class="menu__social" v-if="socials?.length">
          <div
            class="menu__social-title c-black uppercase"
            :style="{
              '--duration': duration + 'ms',
              'transition-delay': isMobile ? maxDelay + delay + 'ms' : delay + 'ms',
            }"
          >
            {{ $t("stay in touch") }}
          </div>

          <div
            class="menu__social-items d-flex"
            :style="{
              '--duration': duration + 'ms',
            }"
          >
            <template v-for="(item, index) in socials">
              <div
                class="menu__social-item"
                :style="{
                  'transition-delay': isMobile
                    ? maxDelay + delay * (index + 2) + 'ms'
                    : delay +
                      ((maxDelay - delay) / (socials.length - 1)) * (index + 1) +
                      'ms',
                }"
              >
                <Link :label="item?.title" :link="(item as any)" class="c-black">{{ item?.title }}</Link>
              </div>
            </template>
          </div>
        </div>

        <div
          class="menu__submenu d-flex dir-col"
          :style="{
            '--duration': duration + 'ms',
          }"
          v-if="submenus?.length"
        >
          <template v-for="(item, index) in submenus">
            <div
              class="menu__submenu-item d-inline-block"
              :style="{
                'transition-delay':
                  isMobile && socials?.length
                    ? maxDelay + (socials.length + 1) * delay + delay * (index + 1) + 'ms'
                    : delay + ((maxDelay - delay) / (submenus.length - 1)) * index + 'ms',
              }"
            >
              <Link
                v-if="item?.titles?.[lang]?.title"
                :label="item?.titles?.[lang]?.title"
                class="c-black uppercase"
                :link="buildLinkObjFromMenu(item)"
              >
                {{ item?.titles?.[lang]?.title }}
              </Link>
            </div>
          </template>
        </div>
      </div>

      <div
        class="menu__dots d-flex dir-col pos-abs"
        :style="{
          '--duration': duration + 'ms',
        }"
      >
        <div
          v-for="dot in dots"
          class="menu__dot bg-black"
          :style="{
            'transition-delay':
              isMobile && socials?.length && submenus?.length
                ? maxDelay +
                  (socials.length + 1 + submenus.length) * delay +
                  delay * dot +
                  'ms'
                : delay + ((maxDelay - delay) / (dots - 1)) * (dot - 1) + 'ms',
          }"
        ></div>
      </div>
    </div>

    <div
      @click="closeModal"
      class="menu__close d-flex j-center al-center pointer pos-abs"
    >
      <X />
    </div>
  </menu>
</template>

<style lang="scss">
.menu {
  top: 0;
  left: 0;
  z-index: 200;
  overflow: auto;

  &::-webkit-scrollbar {
    display: none;
  }

  @include maxq(tablet) {
    flex-direction: column;
  }

  &::before {
    content: "";
    width: 1px;
    height: 100%;
    background-color: $brightGray;
    position: fixed;
    right: 50%;
    top: 0;
    transform: translateY(-100%);
    @include easeInOut(var(--duration), all);
    transition-delay: var(--delay);

    @include maxq(desktop) {
      right: 47%;
    }

    @include maxq(mobile) {
      content: none;
    }
  }

  &__logo-lang {
    @include maxq(mobile) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__logo {
    width: 480px;
    margin-right: 96px;

    @include maxq(desktop-2k) {
      width: 320px;
    }

    @include maxq(desktop-lg) {
      width: 242px;
      margin-right: 48px;
    }

    @include maxq(mobile) {
      margin-bottom: 32px;
    }

    @include maxq(mobile-sm) {
      width: 165px;
    }
  }

  .language__dropdown {
    margin-right: 0;

    @include maxq(mobile) {
      height: auto;
      padding: 0;
      margin-bottom: 32px;
    }

    .drop-content {
      @include maxq(mobile) {
        right: 16px;
      }
    }
  }

  &__left {
    padding: 96px;

    @include maxq(desktop-2k) {
      padding: 64px;
    }

    @include maxq(desktop-lg) {
      padding: 45px 32px 45px 48px;
    }

    @include maxq(desktop) {
      width: 53%;
    }

    @include maxq(tablet) {
      width: 100%;
      padding: 45px 96px 0 48px;
    }

    @include maxq(mobile) {
      padding: 48px;
      padding-bottom: 0;
    }

    @include maxq(mobile-sm) {
      padding: 24px 14px;
      padding-bottom: 0;
    }
  }

  &__top {
    opacity: 0;
    @include easeInOut(var(--duration-small), opacity);

    @include maxq(tablet) {
      max-width: 86%;
    }

    @include maxq(mobile) {
      max-width: 100%;
      flex-direction: column;
      align-items: flex-start;
    }
  }

  .button--5 {
    @include maxq(desktop-sm) {
      padding: 4px;

      .icon {
        width: 16px;
        height: 16px;
      }

      .button__content {
        font-size: 14px;
      }
    }

    @include maxq(tablet) {
      padding: 12px 16px;

      .icon {
        width: 24px;
        height: 24px;
      }

      .button__content {
        font-size: 15px;
      }
    }

    @include maxq(mobile) {
      padding: 0;
    }
  }

  &__items {
    margin-top: 153px;

    @include maxq(desktop-2k) {
      margin-top: 101px;
    }

    @include maxq(desktop-lg) {
      margin-top: 73px;
    }

    @include maxq(mobile) {
      margin-top: 44px;
    }

    @include maxq(mobile-sm) {
      margin-top: 22px;
    }
  }

  &__item {
    font-size: 208px;
    line-height: 216px;
    padding: 43px 8px;
    transform: translateY(-15px);
    opacity: 0;
    @include easeInOut(var(--duration), all);

    @include maxq(desktop-2k) {
      font-size: 138px;
      line-height: 146px;
      padding: 28px 8px;
    }

    @include maxq(desktop-lg) {
      font-size: 104px;
      line-height: 126px;
      padding: 12px 8px;
    }

    @include maxq(desktop) {
      font-size: 80px;
      line-height: 98px;
    }

    @include maxq(tablet) {
      padding: 12px 0;
    }

    @include maxq(mobile) {
      font-size: 48px;
      line-height: 58px;
    }

    @include maxq(mobile-sm) {
      font-size: 32px;
      line-height: 36px;
    }

    svg {
      height: 120px;
      width: auto;
      transform: rotate(90deg) translateY(-170px);
      opacity: 0;
      @include easeInOut(var(--duration), all);

      @include maxq(desktop-2k) {
        height: 80px;
        transform: rotate(90deg) translateY(-130px);
      }

      @include maxq(desktop-lg) {
        height: 60px;
        transform: rotate(90deg) translateY(-110px);
      }

      path {
        stroke: #1F1F1F;
        stroke-width: 4px;
      }
    }

    @include hover {
      svg {
        transform: rotate(90deg) translateY(-90px);
        opacity: 1;

        @include maxq(desktop-2k) {
          transform: rotate(90deg) translateY(-70px);
        }

        @include maxq(desktop-lg) {
          transform: rotate(90deg) translateY(-50px);
        }
      }
    }
  }

  &__right {
    padding: 96px;

    @include maxq(desktop-2k) {
      padding: 64px;
    }

    @include maxq(desktop-lg) {
      padding: 48px 96px 48px 64px;
    }

    @include maxq(desktop) {
      width: 47%;
    }

    @include maxq(tablet) {
      width: 100%;
      padding: 0 96px 166px 48px;
    }

    @include maxq(mobile) {
      padding: 0 70px 153px 48px;
    }

    @include maxq(mobile-sm) {
      padding: 0 40px 120px 14px;
    }
  }

  &__close {
    padding: 15px;
    top: 96px;
    right: 96px;
    border: 1px solid rgba($black, 0.2);
    opacity: 0;
    @include easeInOut(var(--duration-small), all);

    @include maxq(desktop-2k) {
      padding: 11px;
      top: 64px;
      right: 64px;
    }

    @include maxq(desktop-lg) {
      padding: 7px;
      top: 48px;
      right: 48px;
    }

    @include maxq(mobile-sm) {
      top: 24px;
      right: 14px;
    }

    .icon {
      width: 64px;
      height: 64px;
      background: $black;

      @include maxq(desktop-2k) {
        width: 40px;
        height: 40px;
      }

      @include maxq(desktop-lg) {
        width: 32px;
        height: 32px;
      }

      svg {
        width: 30px;
        height: 100%;

        @include maxq(desktop-2k) {
          width: 22px;
        }

        @include maxq(desktop-lg) {
          width: 16px;
        }
      }
    }

    @include hover {
      padding: 21px;
      top: 90px;
      right: 90px;

      @include maxq(desktop-2k) {
        padding: 16px;
        top: 59px;
        right: 59px;
      }

      @include maxq(desktop-lg) {
        padding: 12px;
        top: 43px;
        right: 43px;
      }
    }
  }

  &__social-submenu {
    margin-top: 337px;

    @include maxq(desktop-2k) {
      margin-top: 224px;
    }

    @include maxq(desktop-lg) {
      margin-top: 168px;
    }

    @include maxq(desktop) {
      margin-top: 146px;
    }

    @include maxq(tablet) {
      margin-top: 76px;
    }

    @include maxq(mobile) {
      margin-top: 44px;
    }
  }

  &__social {
    &-title {
      font-size: 48px;
      line-height: 56px;
      transform: translateY(-15px);
      opacity: 0;
      @include easeInOut(var(--duration), all);

      @include maxq(desktop-2k) {
        font-size: 32px;
        line-height: 40px;
      }

      @include maxq(desktop-lg) {
        font-size: 24px;
        line-height: 30px;
      }
    }

    &-items {
      margin-top: 16px;

      @include maxq(mobile) {
        flex-direction: column;
      }
    }

    &-item {
      margin-right: 16px;
      transform: translateY(-15px);
      opacity: 0;
      @include easeInOut(var(--duration), all);

      @include maxq(mobile) {
        margin-right: 0;
        margin-bottom: 8px;
      }

      &:last-child {
        margin-right: 0;

        @include maxq(mobile) {
          margin-bottom: 0;
        }
      }

      a {
        font-size: 32px;
        line-height: 40px;
        padding: 2px 8px;
        position: relative;
        left: -8px;
        @include easeInOut(350ms, all);

        @include maxq(desktop-2k) {
          font-size: 22px;
          line-height: 26px;
        }

        @include maxq(desktop-lg) {
          font-size: 15px;
          line-height: 20px;
        }

        @include hover {
          background-color: $black;
          color: $white;
        }
      }
    }
  }

  &__submenu {
    margin-top: 134px;

    @include maxq(desktop-2k) {
      margin-top: 87px;
    }

    @include maxq(desktop-lg) {
      margin-top: 67px;
    }

    &-item {
      margin: 19px 0;
      transform: translateY(-15px);
      opacity: 0;
      @include easeInOut(var(--duration), all);

      @include maxq(desktop-2k) {
        margin: 12px 0;
      }

      @include maxq(desktop-lg) {
        margin: 8px 0;
      }

      a {
        font-size: 64px;
        line-height: 72px;
        padding: 1px 8px;
        position: relative;
        left: -8px;
        @include easeInOut(350ms, all);

        @include maxq(desktop-2k) {
          font-size: 42px;
          line-height: 50px;
        }

        @include maxq(desktop-lg) {
          font-size: 32px;
          line-height: 39px;
        }

        @include maxq(mobile-sm) {
          font-size: 26px;
          line-height: 30px;
        }

        @include hover {
          background-color: $black;
          color: $white;
        }
      }
    }
  }

  &__dots {
    right: 68px;
    bottom: 68px;

    @include maxq(desktop-2k) {
      right: 48px;
      bottom: 48px;
    }

    @include maxq(mobile-sm) {
      right: 24px;
      bottom: 24px;
    }
  }

  &__dot {
    width: 28px;
    height: 29px;
    margin-bottom: 52px;
    transform: translateY(-15px) skewY(-25deg);
    opacity: 0;
    @include easeInOut(var(--duration), all);

    @include maxq(desktop-2k) {
      width: 18px;
      height: 19px;
      margin-bottom: 40px;
    }

    @include maxq(desktop-lg) {
      width: 13px;
      height: 14px;
      margin-bottom: 28px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &.animate {
    &::before {
      transform: translateY(0);
    }

    .menu {
      &__top {
        opacity: 1;
      }

      &__close {
        opacity: 1;
      }

      &__item {
        transform: translateY(0);
        opacity: 1;
      }

      &__social-title {
        transform: translateY(0);
        opacity: 1;
      }

      &__social-item {
        transform: translateY(0);
        opacity: 1;
      }

      &__submenu-item {
        transform: translateY(0);
        opacity: 1;
      }

      &__dot {
        transform: translateY(0) skewY(-25deg);
        opacity: 1;
      }
    }

    &-end {
      .menu__item {
        transition-delay: unset !important;

        &--disabled {
          opacity: 0.2;
        }
      }
    }
  }
}
</style>
